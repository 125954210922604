
import { defineComponent, PropType } from 'vue'
import { IonGrid, IonRow, IonCol, IonInfiniteScroll, IonInfiniteScrollContent } from '@ionic/vue'
import { IndexingInfo } from '@/core/indexing'
import { localIndex, SORTING, LocalIndex } from '@/core/indexing/local-index'
import ScoreItem from './ScoreItem.vue'

export default defineComponent({
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    ScoreItem,
  },
  props: {
    sort: {
      type: String as PropType<SORTING>,
      default: 'latest',
    },
  },
  data () {
    return {
      it: undefined as any as ReturnType<LocalIndex['iterate']>,
      sheets: [] as IndexingInfo[],
    }
  },
  watch: {
    // re-init when the sorting changes
    sort: 'init',
  },
  methods: {
    async init () {
      this.sheets = []
      this.it = (await localIndex).iterate(this.sort)
    },
    async fetchSheets (ev?: { target: HTMLIonInfiniteScrollElement }) {
      await localIndex // secure that `localIndex` and `this.it` are ready

      const { value: results, done } = await this.it.next()
      if (results) {
        this.sheets.push(...results)
      }

      if (ev) {
        await ev.target.complete()
        if (done) { // all data is loaded
          // disable the infinite scroll
          ev.target.disabled = true
        }
      }
    },
  },
  async created () {
    await this.init()
    void this.fetchSheets()
  },
})


import { defineComponent, PropType } from 'vue'
import type CID from 'cids'
import IpfsImg from '../components/IpfsImg.vue'

export default defineComponent({
  inject: [
    'ipfs',
  ],
  components: {
    IpfsImg,
  },
  props: {
    cid: {
      type: undefined as any as PropType<CID>,
      required: true,
    },
    alt: {
      type: String,
    },
  },
})


import { defineComponent, PropType } from 'vue'
import { IonTitle } from '@ionic/vue'
import { IndexingInfo } from '@/core/indexing'
import ScoreThumbnail from './ScoreThumbnail.vue'

export default defineComponent({
  components: {
    IonTitle,
    ScoreThumbnail,
  },
  props: {
    s: {
      type: undefined as any as PropType<IndexingInfo>,
      required: true,
    },
  },
})
